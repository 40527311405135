import { m } from "framer-motion";
// @mui
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { MotionContainer, varBounce } from "../../components/animate";
import PageNotFoundIllustration from "../../../fe-ui/illustrations/page-not-found-illustration";
import CompactLayout from "../../layouts/compact/layout";
import { Link } from "../../../fe-ui/atoms/Link/Link";
import { useTranslation } from "../../../fe-ui/hooks/use-translation";

export default function Screen404() {
  const t = useTranslation();
  return (
    <CompactLayout>
      <MotionContainer>
        <m.div variants={varBounce().in}>
          <Typography variant={"h3"} paragraph>
            {t("404-title")}
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: "text.secondary" }}>{t("404-text")}</Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <PageNotFoundIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>

        <Button component={Link} href={"/"} size={"large"} variant={"contained"}>
          {t("404-cta")}
        </Button>
      </MotionContainer>
    </CompactLayout>
  );
}
