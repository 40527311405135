import { GetStaticPropsContext } from "next";
import Screen404 from "../ui/screens/error/404-screen";

const Custom404 = (_props) => {
  return <Screen404 />;
};

export const getStaticProps = (_ctx: GetStaticPropsContext) => {
  let strings = {};

  switch (_ctx.locale) {
    case "az":
      strings = {
        "404-title": "Səhifə tapılmadı!",
        "404-text": "",
        "404-cta": "Ana səhifəyə",
        "az-short": "Az",
        "ru-short": "Рус",
        "en-short": "Eng",
        az: "Azərbaycan",
        ru: "Русский",
        en: "English",
        "nav-cta-upload-unit": "Əmlakı yüklə",
      };
      break;
    case "en":
      strings = {
        "404-title": "Page not found!",
        "404-text": "",
        "404-cta": "Go to home",
        "az-short": "Az",
        "ru-short": "Рус",
        "en-short": "Eng",
        az: "Azərbaycan",
        ru: "Русский",
        en: "English",
        "nav-cta-upload-unit": "List property",
      };
      break;
    case "ru":
      strings = {
        "404-title": "Страница не найдена!",
        "404-text": "",
        "404-cta": "На главную",
        "az-short": "Az",
        "ru-short": "Рус",
        "en-short": "Eng",
        az: "Azərbaycan",
        ru: "Русский",
        en: "English",
        "nav-cta-upload-unit": "Сдать жилье",
      };
      break;
  }

  return {
    props: {
      messages: {
        strings,
      },
    },
  };
};

export default Custom404;
